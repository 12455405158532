import * as React from 'react';

export const MarketingGradientShadow = () => {

    return (
        <div className="bg-gradient-bee-vertical-shadow"
            style={{
                zIndex: 4,
                position: 'relative',
                height: '6px',
                width: '100%',
            }} />
    );
};
export const MarketingGradientShadowInverse = () => {

    return (
        <div className="bg-gradient-bee-vertical-shadow-inverse"
            style={{
                height: '6px',
                width: '100%',
            }} />
    );
};
