import { isMobile } from 'react-device-detect';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Fab, List, ListItem, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MarketingAnchor } from './MarketingMenu';
import { StyledTitleRow } from './StyledTitle';
const columns = [
    { headerName: 'ID', },
    { headerName: 'Szczegóły', },
    { headerName: 'Pow. domu*', },
    { headerName: 'Pow. działki*', },
    { headerName: 'Status', },
    { headerName: 'Cena', },
];

const rows = [
    { id: 'B1-1', metraz: '153m²', powDzialki: '375.7m²', status: 'oddanie 2023', karta: true, },
    { id: 'B1-2', metraz: '153m²', powDzialki: '328.6m²', status: 'oddanie 2023', karta: true, },
    { id: 'B2-1', metraz: '153m²', powDzialki: '334.3m²', status: 'oddanie 2023', karta: true, },
    { id: 'B2-2', metraz: '153m²', powDzialki: '329.8m²', status: 'oddanie 2023', karta: true, },
    { id: 'B3-1', metraz: '153m²', powDzialki: '336.8m²', status: 'oddanie 2024', karta: true, },
    { id: 'B3-2', metraz: '153m²', powDzialki: '497.7m²', status: 'oddanie 2024', karta: true, },
    { id: 'S1-1', metraz: '146m²', powDzialki: '442.3m²', status: 'w przygotowaniu', },
    { id: 'S1-2', metraz: '146m²', powDzialki: '203.9m²', status: 'w przygotowaniu', },
    { id: 'S1-3', metraz: '146m²', powDzialki: '202.3m²', status: 'w przygotowaniu', },
    { id: 'S1-4', metraz: '146m²', powDzialki: '201.8m²', status: 'w przygotowaniu', },
    { id: 'S1-5', metraz: '146m²', powDzialki: '278.9m²', status: 'w przygotowaniu', },
];

export const Marketing06_oferta = () => {

    return (
        <>
            <MarketingAnchor id="oferta" />
            <Container >
                <StyledTitleRow text="Oferta" />

                <Row className="">
                    <Col xs={{ span: 12, order: 2, }} lg={{ span: 12, order: 1 }} className="table-responsive">
                        <table className="table table-hover"
                            style={{ fontSize: 16 }}
                        >
                            <thead>
                                <tr >
                                    {columns.map((m, i) =>
                                        <th scope="col"
                                            className="align-middle text-center"
                                            key={m.headerName}>{m.headerName}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((r, i) => <tr key={r.id}>
                                    <th scope="row">{r.id}</th>
                                    <td className="text-center">
                                        {r.karta &&
                                            <Fab variant="extended" size="small"
                                                target="_blank"
                                                href={`/files/${r.id}.pdf`}>
                                                <PictureAsPdfIcon />
                                            </Fab>
                                        }
                                    </td>
                                    <td className="text-center">{r.metraz}</td>
                                    <td className="text-center">{r.powDzialki}</td>
                                    <td className="text-center">{r.status}</td>
                                    <td className="text-center">
                                        {isMobile
                                            ?
                                            <Fab variant="extended" size="small"
                                                className="text-white-shadow bg-gradient-05"
                                                href="callto:+48509958294">
                                                <ContactSupportIcon sx={{ mr: 1 }} />
                                                CENA
                                            </Fab>
                                            :
                                            <Fab variant="extended" size="small"
                                                className="text-white-shadow bg-gradient-05"
                                                href="callto:+48509958294">
                                                <ContactSupportIcon sx={{ mr: 1 }} />
                                                tel. 509 958 294
                                            </Fab>
                                        }
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Typography>
                * Uwaga! Podane wymiary są wymiarami projektowanymi i mogą być różne od wymiarów powykonawczych.
                </Typography>
                <Row className="p-3">
                    <Col xs={12} lg={6} className="">
                        <Paper elevation={12} className="my-3 p-3">
                            Komfort naszych domów zapewniają:
                            <List sx={{
                                listStyleType: 'disc',
                                pl: 3,
                                '& .MuiListItem-root': {
                                    display: 'list-item',
                                },
                            }}>
                                <ListItem>
                                    Duże przeszklenia doświetlające wnętrze
                                </ListItem>
                                <ListItem>
                                    Zadaszony piękny taras
                                </ListItem>
                                <ListItem>
                                    Wysoka izolacja akustyczna i termiczna:
                                    <List sx={{
                                        listStyleType: 'circle',
                                        pl: 2,
                                        '& .MuiListItem-root': {
                                            display: 'list-item',
                                        },
                                    }}>
                                        <ListItem>
                                            mury z pustaków ceramicznych
                                        </ListItem>
                                        <ListItem>
                                            podwójna ściana między segmentami
                                        </ListItem>
                                        <ListItem>
                                            18 cm warstwa styropianu
                                        </ListItem>
                                        <ListItem>
                                            3 szybowe okna o dużej izolacyjności cieplnej
                                        </ListItem>
                                    </List>
                                </ListItem>
                                <ListItem>
                                    Ogrzewanie podłogowe
                                </ListItem>
                                <ListItem>
                                    Instalacja gazowa
                                </ListItem>
                            </List>
                        </Paper>
                    </Col>
                    <Col xs={12} lg={6} className="">
                        <Paper elevation={12} className="my-3" style={{
                            borderRadius: 8,
                            overflow: 'clip',
                        }}>
                            <img src="./img/wizki-ofelia/ofeliaSegment_w7.jpg" alt="wizka" width="100%" />
                        </Paper>
                    </Col>
                    <Col xs={12} >
                        <Typography variant="subtitle1" align="justify" >
                            Ponadto kupując dom na osiedlu Park Baczyńskiego, każdy mieszkaniec będzie miał do dyspozycji <b>własny ogród</b>.
                            <br />
                            W ramach inwestycji zaplanowano <b>wielki wspólny teren rekreacyjny</b>, z placem zabaw, miejscem do grillowania i odpoczynku na łonie natury.
                            <br />
                            Nasza inwestycja ma <b>unikalną lokalizacje</b> „blisko i na uboczu”, znajduje się w ostatniej linii zabudowy przed terenami zielonymi.
                            <br />
                            <br />
                        </Typography>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
