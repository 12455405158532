import { Typography } from '@mui/material';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MarketingAnchor } from './MarketingMenu';
import { StyledTitleRow } from './StyledTitle';

export const Marketing08_lokalizacja = () => {

    return (
        <>
            <MarketingAnchor id="lokalizacja" />
            <Container >
                <StyledTitleRow text="Lokalizacja w Oławie" />
                <Row className="p-3">
                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 12, order: 1 }} className="">
                        <Typography variant="subtitle1" align="justify" >
                            Osiedle "Park Baczyńskiego" umiejscowione jest w willowej części Oławy, spokojnej dzielnicy, blisko rzeki Oława, oraz wylotu na drogę DK 94 Wrocław-Opole.
                            <br />
                            Nad rzeką znajdują się doskonałe miejsca spacerowe i na wycieczki rowerowe. Blisko znajdują się szkoły, przedszkola, szpital, przychodnie i sklepy.
                            <br />
                            Inwestycja jest idealną alternatywą dla rodzin marzących o domu blisko Wrocławia położonym w zielonej i cichej okolicy
                            <br />
                            <br />
                        </Typography>
                    </Col>
                </Row>
                <Row className="">

                    <Col className="" style={{
                        position: 'relative',
                        width: '100%',
                        height: 0,
                        paddingBottom: '56.25%',
                    }}>
                        <iframe src="https://www.youtube.com/embed/UoaVSF_kGno"
                            title="Oława"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-5" />
            </Container>
        </>
    );
};
