import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import * as React from 'react';
import { useCallback } from 'react';
import { berlinMapCoords, CompanyDetails, MapCoords } from '../../api/models/Global';
export const CompanyMapView = (props: {
    company: CompanyDetails,
    height?: string,
    onMapClick?: (coords: MapCoords) => void,
}) => {
    const mapContainerStyle = {
        width: '100%',
        height: props.height ?? '400px',
    };
    const options = {
        disableDefaultUI: true,
        mapId: "3b47b43fdae52c1f",
        zoomControlOptions: {
        }
    }
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAAB-pgbzDAcfFU2Ej9W3kWjT8dcPpvbIE",
        mapIds: ["3b47b43fdae52c1f"],
    })
    const onLoad = useCallback(function onLoad(mapInstance) {
    }, [])
    //function iconBg(): any {
    //    const url = '/img/map.png';
    //    return {
    //        url: url,
    //        scaledSize: {
    //            height: 200,
    //            width: 200,
    //        },
    //        anchor: {
    //            x: 47.5,
    //            y: 200,
    //        },
    //    };
    //}
    function iconLogo(): any {
        const url = '/img/logo-mapa.png';
        return {
            url: url,
            scaledSize: {
                height: 171,
                width: 244,
            },
            anchor: {
                x: 122,
                y: 115,
            },
        };
    }
    //function markerClick() {
    //    const coords = props.company.mapCoords ?? berlinMapCoords;
    //    const label = encodeURIComponent(props.company.title);
    //    const latLng = `${coords.lat},${coords.lng}`;
    //    const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${latLng}&destination_place_id=${label}`;
    //    if (isMobile) {
    //        window.location.href = url;
    //    } else {
    //        window.open(url, '_blank')?.focus();
    //    }
    //}
    function mapClick(e: any) {
        const coords = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        console.log(JSON.stringify(coords));
        if (props.onMapClick) {
            props.onMapClick(coords);
        }
    }
    const renderMap = () => {
        return <GoogleMap
            mapTypeId="terrain"
            options={options}
            onLoad={onLoad}
            onClick={mapClick}
            center={props.company.mapCoords ?? berlinMapCoords}
            zoom={props.company.mapCoords ? 10 : 6}
            mapContainerStyle={mapContainerStyle}
        >
            {props.company.mapCoords && <Marker 
                key={"bg-" + props.company.itemID}
                icon={iconLogo()}
                position={props.company.mapCoords ?? berlinMapCoords}
            />}
        {/*    {props.company.mapCoords && <Marker*/}
        {/*        onClick={markerClick}*/}
        {/*        key={props.company.itemID}*/}
        {/*        icon={iconBg()}*/}
        {/*        title={props.company.title}*/}
        {/*        position={props.company.mapCoords ?? berlinMapCoords}*/}
        {/*    />}*/}
        </GoogleMap>
    }

    if (loadError) {
        return <div>Nie można pokazać mapy</div>
    }

    return isLoaded ? renderMap() : <>wczytywanie...</>
};
