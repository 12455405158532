import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';

export function CspDialog(props: {
    onClose: () => void,
    open: boolean,
    title: string,
    children?: React.ReactNode,
}) {
    const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const handleClose = () => {
        props.onClose();
    };

    return <Dialog onClose={handleClose} open={props.open}
        maxWidth={'md'}
        fullScreen={fullScreen}
    >
        <DialogTitle>{props.title}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        {props.children}
    </Dialog>
}