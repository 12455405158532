import { Card, CardProps } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';

export const EmiCard = (props: CardProps) => {
    const [mouseOver, setMouseOver] = useState(false);
    function handleMouseEnter(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        setMouseOver(true);
    }
    function handleMouseLeave(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        setMouseOver(false);
    }
    function getElevation():number {
        if (props.elevation === undefined) {
            return 0;
        } else {
            return props.elevation;
        }
    }
    return (
        <Card onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                transform: `scale(${mouseOver ? 1.02 : 1.0})`,
                zIndex: (mouseOver ? 1 : 'auto'),
            }}
            {...props}
            elevation={mouseOver ? getElevation() + 10 : getElevation()}
        >
        </Card>
    );
};
