import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Marketing01_MenuAndHeader } from "../marketing/Marketing01_MenuAndHeader";
import { Marketing02_park } from "../marketing/Marketing02_park";
import { Marketing03_inwestycja } from "../marketing/Marketing03_inwestycja";
import { Marketing04_domy } from "../marketing/Marketing04_domy";
import { Marketing05_rzuty } from "../marketing/Marketing05_rzuty";
import { Marketing06_oferta } from "../marketing/Marketing06_oferta";
import { Marketing07_co_nas_wyroznia } from '../marketing/Marketing07_co_nas_wyroznia';
import { Marketing08_lokalizacja } from "../marketing/Marketing08_lokalizacja";
import { Marketing09_kontakt } from "../marketing/Marketing09_kontakt";
import { MarketingFooter } from "../marketing/MarketingFooter";
import { MarketingPageAnchor } from "../marketing/MarketingPageAnchor";

export const MarketingPage = () => {
    //const urlParams = useParams<{ tab: MarketingPageTab }>();
    const [offset, setOffset] = useState(0);

    const homeRef = useRef<HTMLDivElement>(null);
    const parkRef = useRef<HTMLDivElement>(null);
    const inwestycjaRef = useRef<HTMLDivElement>(null);
    const domyRef = useRef<HTMLDivElement>(null);
    const rzutyRef = useRef<HTMLDivElement>(null);
    const ofertaRef = useRef<HTMLDivElement>(null);
    const co_nas_wyrozniaRef = useRef<HTMLDivElement>(null);
    const lokalizacjaRef = useRef<HTMLDivElement>(null);
    const kontaktRef = useRef<HTMLDivElement>(null);

    //useEffect(() => {
    //    switch (urlParams.tab) {
    //        case "home": homeRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "park": parkRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "inwestycja": inwestycjaRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "domy": domyRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "rzuty": rzutyRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "oferta": ofertaRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "co_nas_wyroznia": co_nas_wyrozniaRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "lokalizacja": lokalizacjaRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //        case "kontakt": kontaktRef.current?.scrollIntoView({ behavior: "smooth" }); break;
    //    }
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [urlParams]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    function handleScroll(event: Event) {
        setOffset(window.pageYOffset);
    };
    return (
        <>
            <MarketingPageAnchor ref={homeRef} />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing01_MenuAndHeader />
            <MarketingPageAnchor ref={parkRef} />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing02_park pauseAnimation={Boolean(offset > 800)} />
            <MarketingPageAnchor ref={inwestycjaRef} showBack white />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing03_inwestycja />
            <MarketingPageAnchor ref={domyRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing04_domy />
            <MarketingPageAnchor ref={rzutyRef} showBack white />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing05_rzuty />
            <MarketingPageAnchor ref={ofertaRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing06_oferta />
            <MarketingPageAnchor ref={co_nas_wyrozniaRef} showBack white />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing07_co_nas_wyroznia />
            <MarketingPageAnchor ref={lokalizacjaRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing08_lokalizacja />
            <MarketingPageAnchor ref={kontaktRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing09_kontakt />
            <MarketingPageAnchor showBack />
            <MarketingFooter />
        </>
    );
};
