import { Typography } from '@mui/material';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CspDivider } from '../ui/CspDivider';

export type StyledTitleProps = {
    text?: string,
    white?: boolean,
    href?:string,
}
export const StyledTitleRow = (props: StyledTitleProps) => {
    return <Row className="mb-3">
        <Col />
        <Col className="col-auto">
            <StyledTitle {...props} />
        </Col>
        <Col />
    </Row>
}
export const StyledTitle = (props: StyledTitleProps) => {
    return <div>
        {/*<div className={"bg-gradient-gold d-none "*/}
        {/*    + Animations.roll_in_left_effect() + " "*/}
        {/*    + Animations.delay(8)}*/}
        {/*    style={{*/}
        {/*        height: 100,*/}
        {/*        borderRadius: '50%',*/}
        {/*        position: 'absolute',*/}
        {/*        top: -10,*/}
        {/*        left: -30,*/}
        {/*        opacity: '0.8',*/}
        {/*    }}*/}
        {/*/>*/}
        {/*<div className={"bg-gradient-gold  d-none "*/}
        {/*    + Animations.roll_in_left_effect() + " "*/}
        {/*    + Animations.delay(16)}*/}
        {/*    style={{*/}
        {/*        width: 40,*/}
        {/*        height: 40,*/}
        {/*        borderRadius: '50%',*/}
        {/*        position: 'absolute',*/}
        {/*        top: -20,*/}
        {/*        left: 65,*/}
        {/*        opacity: '0.8',*/}
        {/*    }}*/}
        {/*/>*/}
        <a href={props.href}
            className="a-no-underline text-black text-black-bold2">
            <Typography variant="h4" align="center"
                className={props.white ? "text-white-shadow" : "text-black-shadow"}
                style={{
                    position: 'relative',
                    marginTop: 20,
                    //marginLeft:-28,
                    fontWeight: 'bold',
                }}>
                {props.text ?? "Park Baczyńskiego"}
            </Typography>
            <CspDivider />
        </a>
    </div>
}
