export interface AccessTokenBearer {
    accessToken: string,
}
export interface ValidationResult {
    isValid: boolean,
    message: string,
}
export interface MapCoords {
    lat: number,
    lng: number,
}
export const berlinMapCoords: MapCoords = { lat: 52.520008, lng: 13.404954 };
export const baczynskiegoMapCoords: MapCoords = { "lat": 50.95672708785913, "lng": 17.282980529221724 };

export interface CompanyDetails {
    itemID: string,
    title: string,
    ratingScore?: number,
    ratingVotes?: number,
    address: string,
    category?: string,
    mapCoords: MapCoords,
}

