import { Button, Divider, List, ListItem, Toolbar } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MyRoutes } from '../Pages/MyRoutes';
import { CspDivider } from '../ui/CspDivider';
import { ScreenSizeListener } from '../ui/ScreenSizeListener';
import { VersionInfo } from '../ui/VersionInfo';
import { MarketingPageTab, menuContent } from "./MarketingMenu";


export const MarketingFooter = () => {
    const navigate = useNavigate();

    function menuClick(tab: MarketingPageTab | "terms" | "legal") {
        if (tab === "terms") {
            navigate(MyRoutes.terms);
        } else if (tab === "legal") {
            navigate(MyRoutes.legal);
        }
        else {
            navigate(MyRoutes.marketingPage(tab));
        }
    }
    return (
        <>
            <div className="bg-black"
                style={{
                    minHeight: '240px',
                    width: '100%',
                }} >
                <Container >
                    <Row>
                        <Col xs={12} className="order-first px-0 my-3">
                            <ScreenSizeListener threshold="md"
                                whenAboveThreshold={<>
                                    <Toolbar className="">
                                        <img src="./img/logo-light-inverse.png" width={40} alt="Park Baczyńskiego" />
                                        {menuContent.map((m, i) =>
                                            <Button key={m.text} href={MyRoutes.marketingPage(m.link)}
                                                className="text-white text-weight-bold2 ml-3">
                                                {m.text}
                                            </Button>
                                        )}

                                    </Toolbar>
                                    <CspDivider />
                                    <List className="text-white mx-4 ">
                                        <ListItem button >
                                            Powyższa oferta ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu art. 66 §1 Kodeksu Cywilnego
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("terms")}>
                                            Polityka prywatności
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("legal")}>
                                            Regulamin
                                        </ListItem>
                                        <ListItem >
                                            &copy;{moment(undefined, false).format('yyyy')} Park Baczyńskiego. All rights reserved.
                                        </ListItem>
                                        <ListItem >
                                            <VersionInfo />
                                        </ListItem>
                                    </List>
                                </>
                                }
                                whenThresholdOrBelow={<>
                                    <Toolbar >
                                        <img src="./img/logo-light-inverse.png" width={40} alt="Park Baczyńskiego" />
                                    </Toolbar>
                                    <Divider className="bg-white mx-5 my-4" />
                                    <List className="text-white mx-5 ">
                                        {menuContent.map((m, i) =>
                                            <ListItem key={m.text}>
                                                <Button href={MyRoutes.marketingPage(m.link)} color="secondary">
                                                    {m.text}
                                                </Button>
                                            </ListItem>
                                        )}
                                    </List>
                                    <Divider className="bg-white mx-5 my-4" />
                                    <List className="text-white mx-5 ">
                                        <ListItem button >
                                            Powyższa oferta ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu art. 66 §1 Kodeksu Cywilnego
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("terms")}>
                                            Polityka prywatności
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("legal")}>
                                            Regulamin
                                        </ListItem>
                                        <ListItem >
                                            &copy;{moment(undefined, false).format('yyyy')} Park Baczyńskiego. All rights reserved.
                                        </ListItem>
                                        <ListItem >
                                            <VersionInfo />
                                        </ListItem>
                                    </List>
                                </>}
                            />
                        </Col>
                    </Row>
                </Container >
            </div>
        </>
    );
};
