import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import * as React from 'react';

export type ImageListData = {
    img: string,
    title?: string,
    subtitle?: string,
}
export function CspImageList(props: {
    imgPrefix?: string,
    items: ImageListData[],
}) {
    const pattern = {
        rows: [2, 1, 1, 1, 1, 2, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1,],
        cols: [2, 1, 1, 2, 2, 2, 1, 1, 2, 1, 1, 2, 2, 1, 1, 2,],
    }
    const patternCount = pattern.rows.length;

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${props.imgPrefix ?? ""}${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${props.imgPrefix ?? ""}${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    return <ImageList
        sx={{ width: '100%', height: 650 }}
        variant="quilted"
        cols={4}
        rowHeight={161}
    >
        {props.items.map((item, i) => (
            <ImageListItem key={item.img}
                cols={pattern.cols[i % patternCount]}
                rows={pattern.rows[i % patternCount]}>
                <img
                    {...srcset(item.img, 121,
                        pattern.rows[i % patternCount],
                        pattern.cols[i % patternCount])}
                    alt={item.title ?? item.img}
                    loading="lazy"
                />
                {(item.title || item.subtitle) && <ImageListItemBar
                    title={item.title}
                    subtitle={item.subtitle}
                />}
            </ImageListItem>
        ))}

    </ImageList>
}