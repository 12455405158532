import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MarketingAnchor, MarketingMenu } from "./MarketingMenu";
import { StyledTitle } from "./StyledTitle";

export const Marketing01_MenuAndHeader = (props: {
}) => {
    //const navigate = useNavigate();
    //function gotoApp(event: React.MouseEvent<HTMLElement>) {
    //    navigate(MyRoutes.home);
    //};
    return (
        <>
            <div className=""
                style={{
                    overflow: 'hidden',
                    marginTop: -20,
                }}
            >
                <MarketingAnchor id="home" />
                <Container>
                    <Row>
                        <Col />
                        <Col className="col-auto"
                            style={{
                                position: 'relative',
                                top: 20,
                            }}>
                            <StyledTitle text="Park Baczyńskiego" href="#park"/>
                        </Col>
                        <Col />
                    </Row>
                    <Row
                    >
                        <Col xs={12} className="order-first px-0 mt-4">
                            <MarketingMenu />
                        </Col>
                        <Col xs={{ span: 12, order: 1, }}
                            md={{ span: true, order: 2, }}
                            xl={{ span: true, order: 2, }}
                            style={{
                            }}
                        >
                        </Col >
                        <Col xs={0}
                            xl={{ span: 2, order: 3, }}
                        />
                    </Row>
                </Container>

            </div>
        </>
    );
};
