import { Card, CardContent, CardMedia, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MarketingAnchor } from './MarketingMenu';
import { StyledTitleRow } from './StyledTitle';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circle1: {
            borderRadius: '50%',
            border: '4px white solid',
            position: 'absolute',
            top: '70px',
            left: '0px',
        },
        circle2: {
            borderRadius: '50%',
            border: '4px white solid',
            position: 'absolute',
            top: '10px',
            left: '240px',
        },
    }),
);

export const Marketing03_inwestycja = () => {
    const classes = useStyles();

    return (
        <>
            <div className="bg-gradient-bee-diagonal">
                <MarketingAnchor id="inwestycja" />
                <Container>
                    <StyledTitleRow text="Inwestycja" white />
                    <Row className="mt-3">
                        <Col xs={{ span: 12, order: 2 }} lg={{ span: 7, order: 1 }} className="my-5">
                            <Typography variant="subtitle1" align="justify"
                                className="text-white-shadow"
                            >
                                Osiedle "Park Baczyńskiego" składa się z <b>jedenastu domów jednorodzinnych</b> w zabudowie bliźniaczej i szeregowej ze wspólnym terenem rekreacyjnym, stworzonym specjalnie dla mieszkańców.
                                <br />
                                <br />
                                Nasza <b>unikalna lokalizacja</b> blisko przyrody i udogodnień miasta sprawia, że jest to wymarzone miejsce do zamieszkania dla rodzin, osób prowadzących własną działalność czy pracujących zdalnie.
                                <br />
                                <br />
                                Domy są przestronne. Wszystkie mają ogrody a ich mieszkańcy mogą korzystać ze wspólnego terenu rekreacyjnego, w obrębie osiedla. 
                                <b> Każdy dom posiada zadaszony taras, garaż i poddasze do zagospodarowania. </b> 
                                Atrakcyjny wygląd zabudowy podkreśla nowoczesna elewacja oraz duże przeszklenia.
                                <br />
                                <br />
                                <b>Inwestycja jest kilkuetapowa.</b> 
                                <br />
                                Aktualnie w przedsprzedaży dostępne są dwa domy jednorodzinne w zabudowie bliźniaczej. Finalizacja pierwszego etapu inwestycji zaplanowana jest na 2 kwartał 2023 roku.
                                Wkrótce ruszy przedsprzedaż kolejnych dwóch domów w zabudowie bliźniaczej. Zakończenie drugiego etapu inwestycji zaplanowane jest na 4 kwartał 2023r.
                                <br />
                            </Typography>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} lg={{ span: 5, order: 2 }} className=""
                            style={{
                                minHeight: '500px',
                            }}
                        >
                            <img src="./img/Wizki/3.PNG" width="340" height="340" alt="wizka" className={classes.circle1} />
                            <img src="./img/Wizki/14.png" width="200" height="200" alt="wizka" className={classes.circle2} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 1 }} className="my-5">
                            <Card sx={{ maxWidth: 345, height: '100%', marginX: 'auto' }} elevation={12}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="./img/Wizki/2.PNG"
                                    alt="wizka"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Park
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Na rozległej polanie, w wewnętrznej części osiedla, zaprojektowana jest przestrzeń rekreacyjna dla mieszkańców.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 1 }} className="my-5">
                            <Card sx={{ maxWidth: 345, height: '100%', marginX: 'auto' }} elevation={12}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="./img/Wizki/4.PNG"
                                    alt="wizka"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Plac Zabaw
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Doskonale wiemy jak ważne dla rozwoju dzieci jest wspólne miejsce zabaw, gdzie będą mogły radośnie spędzać czas z sąsiadami.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 1 }} className="my-5">
                            <Card sx={{ maxWidth: 345, height: '100%', marginX: 'auto' }} elevation={12}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="./img/Wizki/10.png"
                                    alt="wizka"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Ogrody
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Oaza spokoju i możliwości własnej aranżacji przydomowej zieleni przy każdym domu.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 1 }} className="my-5">
                            <Card sx={{ maxWidth: 345, height: '100%', marginX: 'auto' }} elevation={12}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="./img/Wizki/12.png"
                                    alt="wizka"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Przestrzeń
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Przestronne domy, doskonale zaprojektowana przestrzeń wspólna, bliskość rzeki i miasta oraz całej potrzebnej infrastruktury.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            </div>
        </>
    );
};
