import { ThemeProvider } from '@mui/material';
import * as React from 'react';
import { BrowserRouter } from "react-router-dom";
import { MarketingPage } from './components/Pages/MarketingPage';
import { theme } from './components/ui/Theme';
import './scss/custom.scss';
import './scss/gradients.scss';

function App() {

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <div style={{ height: '100%' }} >
                    <MarketingPage />
                </div >
            </BrowserRouter>
        </ThemeProvider >
    );
}

export default App;
