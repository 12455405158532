import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { baczynskiegoMapCoords } from '../../api/models/Global';
import { MarketingQuestionMessage } from "../../api/models/Marketing";
import { CompanyMapView } from "../details/CompanyMapView";
import { ButtonShadow } from "../ui/ButtonShadow";
import { ContactEmail, ContactPhone } from './ContactPhone';
import { MarketingAnchor } from './MarketingMenu';

export const Marketing09_kontakt = () => {
    const [state, setState] = useState<MarketingQuestionMessage & {
        inProgress: boolean,
        hasSent: boolean,
        hasAttempted: boolean,
    }>({
        message: "",
        name: "",
        inProgress: false,
        hasSent: false,
        hasAttempted: false,
    });
    //async function sendClick() {
    //    if (state.inProgress) return;
    //    if (!state.hasAttempted) {
    //        setState({
    //            ...state,
    //            hasAttempted: true,
    //        });
    //    }
    //    if (state.hasSent) {
    //        setState({
    //            ...state,
    //            hasSent: false,
    //        });
    //    }
    //    if (!isValid()) return;

    //    setState({
    //        ...state,
    //        inProgress: true,
    //    });
    //    const result = await api.Marketing.postQuestion(state);
    //    if (result) {
    //        setState({
    //            ...state,
    //            message: "",
    //            name: "",
    //            inProgress: false,
    //            hasSent: true,
    //            hasAttempted: false,
    //        });
    //    } else {
    //        setState({
    //            ...state,
    //            inProgress: false,
    //            hasSent: false,
    //            hasAttempted: false,
    //        });
    //    }
    //}
    function isValid(): boolean {
        if (!state.message || !state.name) {
            return false;
        } else {
            return true;
        }
    }
    return (
        <>
            <MarketingAnchor id="kontakt" />
            <Container >
                <Row className="my-3">
                    <Col xs={12} lg={true} className="my-3">
                        <Typography variant="h4" align="center" className="text-weight-bold2">
                            Napisz do nas
                        </Typography>
                        <Typography variant="subtitle2" align="justify" >
                            <br />
                            Jeżeli masz pytania, lub chciałbyś dowiedzieć się czegoś na temat inwestycji, skontaktuj się z nami.
                            <br />
                            <br />
                        </Typography>
                        <Container className="p-0 mb-5">
                            <Row className="">
                                <Col xs={12} md={6} className="p-1 m-0">
                                    <ContactEmail />
                                </Col>
                                <Col xs={12} md={6} className="p-1 m-0">
                                    <ContactPhone />
                                </Col>
                            </Row>
                        </Container>
                        <CompanyMapView company={{
                            ratingScore: 5,
                            ratingVotes: 999,
                            address: '',
                            category: 'book',
                            mapCoords: baczynskiegoMapCoords,
                            itemID: '',
                            title: '',
                        }} />
                    </Col>
                    <Col xs={1} />
                    <Col xs={12} lg={true} className="my-3">
                        <Typography variant="h4" align="center" className="text-weight-bold2">
                            Formularz kontaktowy
                            <br />
                        </Typography>
                        <div style={{
                            backgroundColor: '#EBEAED',
                            padding: '20px',
                        }}>
                            <TextField fullWidth variant="outlined"
                                className="my-3"
                                multiline
                                rows={6}
                                placeholder="Wiadomość"
                                label="Wiadomość"
                                value={state.message}
                                onChange={(event) => setState({
                                    ...state,
                                    message: event.target.value,
                                })}
                            />
                            <TextField fullWidth variant="outlined"
                                className="my-3"
                                placeholder="Imię"
                                label="Imię"
                                autoComplete="Imię"
                                value={state.name}
                                onChange={(event) => setState({
                                    ...state,
                                    name: event.target.value,
                                })}
                            />
                            {state.hasAttempted && !isValid() &&
                                <Typography>Proszę, uzupełnij formularz.
                                </Typography>
                            }
                            <Button variant="contained" color="primary"
                                href={`mailto:agata@park-baczynskiego.pl?subject=${encodeURIComponent(`Zapytanie od ${state.name}`)}&body=${encodeURIComponent(state.message ?? '')}`}
                                disableElevation>
                                {state.inProgress
                                    ? <CircularProgress size="25px" />
                                    : "Wyślij"
                                }
                                <ButtonShadow />
                            </Button>
                            {state.hasSent &&
                                <Typography>Wiadomość wysłana
                                </Typography>
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="my-5" />
            </Container>
        </>
    );
};
