import { Paper } from '@mui/material';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CspDialog } from '../ui/CspDialog';

export function DialogOlawa(props: {
    onClose: () => void,
    open: boolean,
}) {
    return (
        <CspDialog onClose={props.onClose}
            open={props.open}
            title="Oława - samodzielne miasto niedaleko Wrocławia"
        >
            <Paper elevation={16} className="w-100 m-1">
                <img alt="oława" src="./img/olawa.jpg" width='100%' />
            </Paper>
            <div className="p-3">
                Oława to miasto położone w dolinie dwóch rzek Odry i Oławy. W odległości <b>26 km od Wrocławia.</b>
                <br />
                <br />
                Jest świetnie skomunikowana z Wrocławiem.<br />
                - połączenie kołowe (samochody, autobusy PKS, busy firm prywatnych), niedaleko jest zjazd z autostrady A4.<br />
                - jak i kolejowe (stacja PKP Oława i PKP Lizawice, częste połączenia PKP, propozycja rozwiązań typu Park and Ride)<br />
                Dzięki czemu łatwo możemy podróżować do Wrocławia.<br />
                <br />
                Oława, ze względu na bliskość do Wrocławia czasem jest określana jako "sypialnia" Wrocławia.<br />
                Jednak w odróżnieniu od siedlisk powstających wokoło Wrocławia, oferuje swym mieszkańcom pełną infrastrukturę miejską.<br />
                Prawa miejskie uzyskała już w XIII w, ma więc długą historię, posiada urokliwy ryneczek z ratuszem, bazyliką oraz zabudowaniami zamkowymi.<br />
                Aktualnie jest <b>stolicą powiatu</b>, zamieszkuje tu ponad 30 000 osób.<br />
                To prężnie funkcjonujące miasto z bogatą infrastrukturą gospodarczą, medyczną, kulturalną, edukacyjną, sportową i administracyjną.<br />
                Większość spraw załatwimy "na miejscu", szybko i sprawnie.<br />
                <br />
                Dla młodszych mieszkańców, Oława posiada <b>rozbudowaną sieć szkół</b> (od żłobków i przedszkoli, po licea, technikum, szkoły branżowe, szkołę muzyczną i dla dorosłych).<br />
                Bogatą ofertę zajęć dodatkowych dla dzieci proponuje wiele lokalnych firm.<br />
                <b>Możemy rozwijać pasje</b> sportowe, taneczne, artystyczne, w zakresie programowania, jeździectwa i wiele innych.<br />
                A wszystko to w bliskiej odległości od domu, często w zasięgu pieszym lub rowerowym.<br />
                <br />
                W wolnym czasie możemy wybrać się do parku miejskiego, pospacerować nad Odrą lub Oławą, skorzystać z oferty wielu placów zabaw, lub terenu rekreacyjnego przy basenie.<br />
                <br />
                Oława posiada własny <b>aquapark</b>, dwie galerie handlowe, kina, sale widowiskową, siłownie i wszelkie usługi przydatne mieszkańcom.<br />
                <br />
                Bogata oferta restauracji pozwala zadowolić nawet najbardziej wybredne podniebienia.<br />
                Znajdziemy tu zarówno <b>restauracje</b> serwujące kuchnie włoską - popularne pizzerie, jak i kuchnię tradycyjną, bary mleczne, gyros, sushi, burgery i in.<br />
                Fani piwa będą zachwyceni <b>Browarem Probus</b>, który powstał w zabytkowych budynkach blisko centrum i warzy własne piwa.<br />
                <br />
                Po mieście możemy wygodnie poruszać się spacerem, lub rowerowo (miasto kładzie nacisk na ciągłe rozbudowywanie sieci ścieżek rowerowych).<br />
                Oława posiada również sieć komunikacji miejskiej. <b>Najbliższy przystanek</b> autobusowy znajduje się przy Szpitalu (500m) od osiedla.<br />
                Oczywiście, możemy poruszać się również samochodem lub taxi.<br />
                <br />
                Oława jest świetnym miejscem do życia, prowadzenia biznesu i odpoczynku.<br />
            </div>
        </CspDialog>
    );
}