import Check from '@mui/icons-material/Check';
import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CspImageList } from '../ui/CspImageList';
import { MarketingAnchor } from './MarketingMenu';
import { StyledTitleRow } from './StyledTitle';

export const Marketing04_domy = () => {
    const images = [
        { img: './img/wizki-ofelia/ofeliaSegment_w7.jpg', title: '', },
        { img: './img/wizki-ofelia/ofeliaSegment_w2.jpg', title: '', },
        { img: './img/Wizki/13.PNG', title: '', },
        { img: './img/Wizki/4.PNG', title: '', },
        { img: './img/Wizki/2.PNG', title: '', },
        { img: './img/wizki-ofelia/ofeliaSegment_w4.jpg', title: '', },
        { img: './img/Wizki/11.PNG', title: '', },
        { img: './img/Wizki/8.PNG', title: '', },
        { img: './img/Wizki/1.PNG', title: '', },
        { img: './img/Wizki/5.PNG', title: '', },
        { img: './img/Wizki/3.PNG', title: '', },
        { img: './img/Wizki/7.png', title: '', },
        { img: './img/Wizki/12.png', title: '', },
        { img: './img/Wizki/6.PNG', title: '', },
        { img: './img/Wizki/10.png', title: '', },
        { img: './img/Wizki/9.PNG', title: '', },
        { img: './img/Wizki/POK4.png', title: '', },
        { img: './img/Wizki/POKOJ2.png', title: '', },
        { img: './img/Wizki/POKOJ3.png', title: '', },
        { img: './img/Wizki/SYP1.1.png', title: '', },
        { img: './img/Wizki/WIZ1.1.png', title: '', },
        { img: './img/Wizki/WIZ2.png', title: '', },
        { img: './img/Wizki/WIZ3.png', title: '', },
        { img: './img/Wizki/STYL1.jpg', title: '', },
    ];
    const atuty1 = [
        { title: 'Jasne wnętrza', text: 'zapewniane przez duże przeszklenia', icon: 'architektura.png' },
        { title: 'Ustawne pokoje', text: 'aż cztery sypialnie.', icon: 'plan.png' },
        { title: 'Otwarta kuchnia', text: 'połączona z jadalnią i salonem', icon: 'eko.png' },
        { title: 'Salon', text: 'z możliwością montażu kominka', icon: 'eko.png' },
        { title: 'Zadaszony taras', text: 'pięknie dopełniający salon', icon: 'eko.png' },
    ];
    const atuty2 = [
        { title: 'Ogród', text: 'oaza piękna i spokoju', icon: 'eko.png' },
        { title: 'Garaż', text: 'w bryle budynku', icon: 'eko.png' },
        { title: 'Poddasze', text: 'przestrzeń do przechowywania', icon: 'magazyn.png' },
        { title: 'Ogrzewanie podłogowe', text: 'w całym domu', icon: 'ogrzewanie.png' },
        { title: 'Park', text: 'na przestronnej łące wewnątrz osiedla', icon: 'plac_zabaw.png' },
    ];
    const cechy = [
        { title: 'Powierzchnia domu', text: '153 m²', icon: 'n_powierzchnia.PNG' },
        { title: 'Ilość pokoi', text: '5', icon: 'n_ilosc_pokoi.PNG' },
        { title: 'Garaż', text: '17 m²', icon: 'n_garaz.PNG' },
        { title: 'Działka', text: 'ok 330m²', icon: 'n_ogrod.PNG' },
        { title: 'Balkon i taras', text: '24 m²', icon: 'n_taras.PNG' },
        { title: 'Wspólny teren rekreacyjny', text: 'ok. 3 190 m²', icon: 'n_teren_rekreacyjny.PNG' },
    ];

    return (
        <>
            <Container>
                <MarketingAnchor id="domy" />

                <StyledTitleRow text="Etap 1" />
                <StyledTitleRow text="Dom - segment bliźniaka" />
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto">
                        <Typography variant="subtitle1" align="justify" >
                            Prezentujemy Państwu domy, które zostały <b>starannie zaprojektowane</b> z myślą o komforcie i wygodzie ich użytkowania.<br />
                            <br />
                            Nasze domy mają otwartą przestrzeń łączącą kuchnię, jadalnię i salon. Duże przeszklenia rozświetlające wnętrza, oraz ustawne pokoje.
                            Dzięki temu przestrzeń mieszkalna jest jasna, wygodna i dopasowana do potrzeb mieszkańców<br />
                            <br />
                            Każdy z domów posiada <b>piękny zadaszony taras</b> i przydomowy ogród.<br />
                            <br />
                            Wszystkie domy posiadają <b>garaż</b>, znajdujący się w bryle budynku, <b>miejsce postojowe</b> przed garażem oraz <b>poddasze</b> do zagospodarowania przez mieszkańców.<br />
                            <br />
                            <b>Dodatkowo</b>, w ramach zakupu domu, proponujemy Państwu <b>wspólny teren rekreacyjny</b>.
                            Jest to duża łąka (<b>ok 3 190 m2</b>) na której zaplanowaliśmy plac zabaw, miejsce do grillowania i odpoczynku na łonie natury.<br />
                            <br />
                        </Typography>
                    </Col>
                    <Col />
                </Row>

                <Row className="pb-3">
                    {cechy.map((m, i) =>
                        <Col key={m.title} xs={6} lg={2} className="m-0 mb-1 p-0">
                            <Paper elevation={3} className="p-3 mx-2 h-100">
                                <img src={`./img/ikony/${m.icon}`} height="50" alt="ico" className="mx-auto d-block" />
                                <Typography variant="subtitle2" align="center" className="my-3">
                                    <b>{m.title}</b>
                                    <br />
                                    {m.text}
                                </Typography>
                            </Paper>
                        </Col>
                    )}
                </Row>
                <Row className="mt-3">
                    <Col >
                        <Paper elevation={12}>
                            <CspImageList items={images} />
                        </Paper>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={12} md={6}>
                        <Paper sx={{}}>
                            <MenuList>
                                {atuty1.map((m, i) =>
                                    <MenuItem key={m.title}>
                                        <ListItemIcon>
                                            <Check />
                                        </ListItemIcon>
                                        <ListItemText primary={m.title}
                                            secondary={m.text}
                                            secondaryTypographyProps={{ variant: "subtitle2" }}
                                        />
                                    </MenuItem>
                                )}
                            </MenuList>
                        </Paper>
                        <br />
                    </Col>
                    <Col xs={12} md={6}>
                        <Paper sx={{}}>
                            <MenuList>
                                {atuty2.map((m, i) =>
                                    <MenuItem key={m.title}>
                                        <ListItemIcon>
                                            <Check />
                                        </ListItemIcon>
                                        <ListItemText primary={m.title}
                                            secondary={m.text}
                                            secondaryTypographyProps={{ variant: "subtitle2" }}
                                        />
                                    </MenuItem>
                                )}
                            </MenuList>
                        </Paper>
                        <br />
                    </Col>
                </Row>
            </Container >
        </>
    );
};
