import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Animations } from '../ui/Animations';
import { AnimatedSlider } from '../ui/AnimatedSlider';
import { MarketingGradientShadow, MarketingGradientShadowInverse } from "./MarketingGradientShadow";
import { MarketingAnchor } from './MarketingMenu';

export const Marketing02_park = (props: {
    pauseAnimation: boolean,
}) => {
    function renderSentence(text: string, top: boolean) {
        return <Paper elevation={12} className="p-2 px-4 m-2" square
            style={{
                opacity: 0.5,
            }}
        >
            <Typography variant="h4" align="center"
                className="text-black-shadow"
                style={{
                    position: 'relative',
                    fontWeight: 'bold',
                }}>
                {text}
            </Typography>
        </Paper>;
    }
    return (
        <>
            <MarketingGradientShadow />
            <MarketingAnchor id="park" />
            <div id=""
                style={{
                    marginTop: -6,
                    overflow: 'hidden',
                }}>
                <AnimatedSlider paused={props.pauseAnimation}
                    animationClassNames={[
                        Animations.kenburns_right(),
                        Animations.kenburns_top_right(),
                        Animations.kenburns_bottom_left(),
                        Animations.kenburns_bottom(),
                    ]}
                    containerStyle={{
                        overflow: 'hidden',
                    }}
                >
                    <img src="./img/Wizki/1.PNG" alt="wizki" width="100%" />
                    <img src="./img/Wizki/3.PNG" alt="wizki" width="100%" />
                    <img src="./img/Wizki/7.png" alt="wizki" width="100%" />
                </AnimatedSlider>
                <div className="d-none d-md-block">
                    <AnimatedSlider paused={props.pauseAnimation}
                        animationClassNames={[
                            Animations.slide_in_left() + " " + Animations.delay(8),
                            Animations.swing_in_top_fwd() + " " + Animations.delay(8),
                            Animations.slide_in_blurred_right() + " " + Animations.delay(8),
                        ]}
                        containerStyle={{
                            position: 'absolute',
                            left: 50,
                            top: 200,
                            minWidth: 120,
                            overflow: 'hidden',
                        }}
                    >
                        {renderSentence("Przestronne domy", true)}
                        {renderSentence("Przemyślana koncepcja osiedla", true)}
                        {renderSentence("Świetna lokalizacja", true)}
                        {renderSentence("Rzeka w pobliżu", false)}
                    </AnimatedSlider>
                    <AnimatedSlider paused={props.pauseAnimation}
                        animationClassNames={[
                            Animations.slide_in_left() + " " + Animations.delay(16),
                            Animations.swing_in_top_fwd() + " " + Animations.delay(16),
                            Animations.slide_in_blurred_right() + " " + Animations.delay(16),
                        ]}
                        containerStyle={{
                            position: 'absolute',
                            left: 50,
                            top: 300,
                            minWidth: 120,
                            overflow: 'hidden',
                        }}
                    >
                        {renderSentence("Duże przeszklenia, 5 wygodnych pokoi", false)}
                        {renderSentence("Plac zabaw, teren rekreacyjny, garaże, ogrody", false)}
                        {renderSentence("Pełna infrastruktura w pobliżu", false)}
                        {renderSentence("Wylot na drogę do Wrocławia", false)}
                    </AnimatedSlider>
                </div>
            </div>
            <Container >
                <Row className="p-3">
                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 12, order: 1 }} className="">
                        <Typography variant="subtitle1" align="justify" >
                            Zapraszamy na prezentację <b>nowego osiedla</b> "Park Baczyńskiego" w podwrocławskiej Oławie.
                            <br />
                            Przestronne domy jednorodzinne umieściliśmy w zielonej okolicy nad rzeką, blisko ścieżek rowerowych i szlaków spacerowych.
                            <br />
                            W wewnętrznej części osiedla, zaprojektowaliśmy <b>teren rekreacyjny</b> z placem zabaw, miejscem do grillowania i odpoczynku dla mieszkańców, który z przyjemnością wcielimy w życie w porozumieniu z przyszłymi mieszkańcami.
                            <br />
                            Starannie  zaprojektowane domy tego osiedla pozwalają zapewnić harmonijne życie przyszłym mieszkańcom.
                            <br />
                            <br />
                        </Typography>
                    </Col>
                </Row>
                <Row className="p-3">
                    <Col xs={{ span: 6, order: 2 }} lg={{ span: 3, order: 1 }} className="">
                        <img src="./img/ikony/n_ilosc_pokoi.PNG" alt="wizka" width="60"
                            className="my-3"
                            style={{
                                margin: 'auto',
                                display: 'block',
                            }} />
                        <Typography variant="subtitle1" align="center" >
                            wygodne i przestronne domy
                        </Typography>
                    </Col>
                    <Col xs={{ span: 6, order: 2 }} lg={{ span: 3, order: 1 }} className="">
                        <img src="./img/ikony/n_plac_zabaw.PNG" alt="wizka" width="60"
                            className="my-3"
                            style={{
                                margin: 'auto',
                                display: 'block',
                            }} />

                        <Typography variant="subtitle1" align="center" >
                            plac zabaw i zielony teren rekreacyjny
                        </Typography>
                    </Col>
                    <Col xs={{ span: 6, order: 2 }} lg={{ span: 3, order: 1 }} className="">
                        <img src="./img/ikony/n_mapa.PNG" alt="wizka" width="60"
                            className="my-3"
                            style={{
                                margin: 'auto',
                                display: 'block',
                            }} />
                        <Typography variant="subtitle1" align="center" >
                            w pobliżu drogi łączącej Wrocław i Opole
                        </Typography>
                    </Col>
                    <Col xs={{ span: 6, order: 2 }} lg={{ span: 3, order: 1 }} className="">
                        <img src="./img/ikony/n_okolica.PNG" alt="wizka" width="60"
                            className="my-3"
                            style={{
                                margin: 'auto',
                                display: 'block',
                            }} />
                        <Typography variant="subtitle1" align="center" >
                            najważniejsze potrzeby zaspokoimy w najbliższej okolicy
                        </Typography>
                    </Col>
                </Row>

                <Row className="p-3">
                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 12, order: 1 }} className="">
                        <Typography variant="subtitle1" align="justify" >
                            <br />
                            Wyszukany design osiedla skierowany jest do osób poszukujących rodzinnego domu o nowoczesnej i niebanalnej architekturze utrzymanej w modernistycznym stylu.
                            <br />
                            <br />
                            <br />
                        </Typography>
                    </Col>
                </Row>
            </Container >
            <MarketingGradientShadowInverse />
        </>
    );
};
