import { createTheme } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';

const grad1 = 'silver';
const grad2 = 'lightgray';

const palette = createPalette({
    primary: {
        main: '#E8385A',
        light: '#AFAFAF',
        dark: '#1B1B1B',
    },
    secondary: {
        main: '#BFBFBF',
        light: '#EEEEEE',
    },
    background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
    },
});
export const theme = createTheme({
    palette: {
        primary: palette.primary,
        secondary: palette.secondary,
        background: palette.background,
    },
    typography: {
        fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
        body1: {
        },
        body2: {
            fontSize:18,
        },
        subtitle1: {
        },
        subtitle2: {
        },
        h6: {
        },
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: '20px',
                    marginBottom: '20px',
                },
                rounded: {
                    borderRadius: '20px',
                    '&:first-child': {
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                    },
                    '&:last-child': {
                        borderBottomLeftRadius: '20px',
                        borderBottomRightRadius: '20px',
                    }
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    '&.Mui-expanded': {
                    },
                },
                root: {
                    color: palette.primary.dark,
                    borderRadius: '20px',
                    "&$expanded": {
                        color: palette.primary.main,
                    }
                },
            }
        },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: 'transparent',
                    color: "inherit",
                }
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '& > .MuiChip-avatarColorPrimary': {
                        backgroundColor: 'transparent',
                        color: "inherit",
                    },
                },
                avatarColorPrimary: {
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                colorPrimary: {
                    color: 'white',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    color: 'white',
                    background: `linear-gradient(60.95deg, ${grad1} 0%, ${grad2} 100%)`,
                    fontWeight: 900,
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {
                    color: 'white',
                    background: `linear-gradient(60.95deg, ${grad1} 0%, ${grad2} 100%)`,
                },
                secondary: {
                    color: '#E02359',
                    backgroundColor: 'white',
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.background.paper,
                }
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: palette.primary.main
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: '8px',
                },
            },
        },
        MuiCardMedia: {
            styleOverrides: {
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                },
                indicator: {
                    borderTop: '3px solid',
                    borderTopColor: palette.primary.main,
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderBottom: '3px solid',
                    color: palette.primary.light,
                    borderBottomColor: palette.secondary.light,
                    maxWidth: '',
                    "&$selected": {
                        color: palette.primary.main,
                    }
                },
            },
        },
    },
});

