import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Fab, IconButton, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Animations } from "../ui/Animations";
import { TabsSwitch } from '../ui/TabsSwitch';
import { MarketingGradientShadow, MarketingGradientShadowInverse } from "./MarketingGradientShadow";
import { MarketingAnchor } from './MarketingMenu';
import { StyledTitleRow } from './StyledTitle';


type TableRow = {
    title: string,
    text: string,
    area: string,
}
const parter: TableRow[] = [
    { title: '1.1', text: 'garaż', area: '17.45m²' },
    { title: '1.2', text: 'wiatrołap', area: '3.89m²' },
    { title: '1.3', text: 'pom. gospodarcze', area: '3.77m²' },
    { title: '1.4', text: 'hall', area: '2.15m²' },
    { title: '1.5', text: 'wc', area: '1.82m²' },
    { title: '1.6', text: 'spiżarnia', area: '1.62m²' },
    { title: '1.7', text: 'kuchnia', area: '9.20m²' },
    { title: '1.8', text: 'jadalnia+salon', area: '29.79m²' },
    { title: '1.9', text: 'taras', area: '15.13m²' },
];

const pietro: TableRow[] = [
    { title: '2.1', text: 'hall', area: '6.16m²' },
    { title: '2.2', text: 'pokój 1', area: '14.11m²' },
    { title: '2.3', text: 'pokój 2', area: '11.40m²' },
    { title: '2.4', text: 'łazienka', area: '6.66m²' },
    { title: '2.5', text: 'pokój 3', area: '11.24m²' },
    { title: '2.6', text: 'pokój 4', area: '15.37m²' },
    { title: '2.7', text: 'balkon', area: '8.55m²' },
    { title: '3.1', text: 'poddasze nieużytkowe', area: '18.63m²' },
];

export const TableWithRows = (props: {
    rows: TableRow[]
}) => {
    const columns = [
        { headerName: 'ID', },
        { headerName: 'Pomieszczenie', },
        { headerName: 'Powierzchnia*', },
    ];

    return <Paper className="my-2" square>
        <table className="table table-hover table-sm "
            style={{ fontSize: 16 }}
        >
            <thead>
                <tr >
                    {columns.map((m, i) =>
                        <th scope="col"
                            className="align-middle text-right"
                            key={m.headerName}>{m.headerName}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {props.rows.map((r, i) => <tr key={r.title}>
                    <th scope="row" className="text-right">
                        <IconButton size="small" style={{
                            backgroundColor: '#f3dcbe',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            cursor: 'default',
                        }}>{r.title}</IconButton> </th>
                    <td className="text-right">{r.text}</td>
                    <td className="text-right">{r.area}</td>
                </tr>)}
                <tr >
                    <th scope="row"></th>
                    <th className="text-right"></th>
                    <th className="text-right">{props.rows
                        .reduce(
                            (sum, m) => sum + (+m.area.slice(0, -2)),
                            0)
                        .toFixed(2)
                    }m²</th>
                </tr>
            </tbody>
        </table>
    </Paper>
}
export const Marketing05_rzuty = () => {
    const [tab, setTab] = useState(0);
    const [img2d, setImg2d] = useState(0);

    return (
        <>
            <MarketingGradientShadowInverse />
            <MarketingAnchor id="rzuty" />

            <div className="bg-gradient-bee-diagonal"
                style={{
                    zIndex: 1,
                    position: 'relative',
                    marginTop: '0px',
                }}>
                <Container >
                    <StyledTitleRow text="Rzuty bliźniaka - parter i piętro" white />
                    <Row className="my-5">
                        <Col className="m-0 p-0" />
                        <Col className="col-auto">
                            <TabsSwitch tabs={["Parter", "Piętro"]}
                                selectedTab={tab}
                                onTabChanged={setTab}
                            />
                        </Col>
                        <Col className="m-0 p-0" />
                    </Row>
                    {tab === 0 && <Row className="py-5" style={{
                    }}>
                        <Col xs={12} md={6} className={Animations.slide_in_blurred_left() + " " + Animations.delay(4)}>
                            <Paper elevation={12} square>
                                <Fab size="small"
                                    className={"bg-gradient-bee-diagonal text-white-shadow "
                                        + Animations.heartbeat()}
                                    onClick={() => setImg2d(1 - img2d)}
                                    style={{
                                        position: 'absolute',
                                        top: 20,
                                        right: 30,
                                    }}
                                >
                                    {img2d ? "3d" : "2d"}
                                </Fab>
                                {img2d ?
                                    <img src="./img/wizki-rzuty/blizniak_parter.png" alt="ofelia" width="100%" />
                                    :
                                    <img src="./img/wizki-rzuty/akso_parter.png" alt="ofelia" width="100%" />
                                }
                            </Paper>
                        </Col>
                        <Col xs={12} md={6} className={Animations.slide_in_blurred_left()}>
                            <Typography variant="subtitle1" align="justify"
                                className="text-white-shadow"
                            >
                                Wchodząc do domu, mijamy <b>pomieszczenie gospodarcze</b> i <b>garaż</b>, który znajduje się w bryle budynku i ma bezpośrednie połączenie z <b>wiatrołapem</b>.
                                <br />
                                Przechodząc przez <b>hall</b>, możemy zajść do <b>łazienki</b> lub zajrzeć do <b>spiżarni</b> pod schodami.
                                <br />
                                Z tego miejsca otwiera się przed nami duża, pięknie doświetlona przestrzeń, na którą składa się <b>kuchnia, jadalnia i salon</b>, o łącznej powierzchni 39m².
                                W salonie można zamontować <b>kominek</b>, w stropie przygotowano instalację kominową.
                                <br />
                                Bezpośrednio z salonu wychodzimy na wygodny, <b>zadaszony taras</b>, który stanowi letnie przedłużenie salonu. Z tarasu rozpościera się widok na przydomowy <b>ogród</b>, wystarczy krok i jesteśmy na łonie natury.
                                <br />
                                Wracając do środka, na wprost kuchni, <b>schodami zabiegowymi</b> wchodzimy na piętro.
                                <br />
                            </Typography>
                            <br />
                            <TableWithRows rows={parter} />
                            <Typography className="text-white-shadow">
                                * Uwaga! Podane wymiary są wymiarami projektowanymi i mogą być różne od wymiarów powykonawczych.
                            </Typography>
                            <br />
                            <Fab variant="extended" size="small"
                                className="ml-2"
                                target="_blank"
                                href="/files/B1-1.pdf">
                                <PictureAsPdfIcon sx={{ mr: 1 }} />
                                Metryczka
                            </Fab>
                            <Fab variant="extended" size="small"
                                className="m-1"
                                target="_blank"
                                href="/files/Standard wykonania domu - Baczynskiego 54.pdf">
                                <PictureAsPdfIcon sx={{ mr: 1 }} />
                                Standard wykonania
                            </Fab>
                        </Col>
                    </Row>
                    }
                    {tab === 1 && <Row className="py-5" >
                        <Col xs={12} md={6} className={Animations.slide_in_blurred_right()} >
                            <Paper elevation={12} square>
                                <Fab size="small"
                                    className={"bg-gradient-bee-diagonal text-white-shadow "
                                        + Animations.heartbeat()}
                                    onClick={() => setImg2d(1 - img2d)}
                                    style={{
                                        position: 'absolute',
                                        top: 20,
                                        right: 30,
                                    }}
                                >
                                    {img2d ? "3d" : "2d"}
                                </Fab>
                                {img2d ?
                                    <img src="./img/wizki-rzuty/blizniak_pietro.png" alt="ofelia" width="100%" />
                                    :
                                    <img src="./img/wizki-rzuty/akso_pietro.png" alt="ofelia" width="100%" />
                                }
                            </Paper>
                        </Col>

                        <Col xs={12} md={6} className={Animations.slide_in_blurred_right() + " " + Animations.delay(8)}>
                            <Typography variant="subtitle1" align="justify"
                                className="text-white-shadow"
                            >
                                Wchodząc schodami na piętro, wkraczamy w przestrzeń typowo prywatną, gdzie znajdziemy <b>cztery wygodne pokoje</b>, balkon i <b>rodzinną łazienkę</b>.
                                <br />
                                <br />
                                Wszystkie pokoje są prostokątne, świetnie doświetlone i mogą spełniać różne funkcje.
                                W zależności od Państwa potrzeb pokój może zostać zaaranżowany na sypialnie, biuro, siłownie, garderobę czy pokój zabaw.
                                <br />
                                Dwa pokoje od frontu, posiadają bezpośrednie wyjście na wspólny <b>balkon</b> z widokiem na teren rekreacyjny wewnątrz osiedla.
                                <br />
                                <br />
                                Do zagospodarowania przez mieszkańców jest również <b>poddasze</b>, świetnie sprawdzi się jako dodatkowa przestrzeń do przechowywania.
                                <br />
                                <br />
                            </Typography>
                            <TableWithRows rows={pietro} />
                            <Typography className="text-white-shadow">
                                * Uwaga! Podane wymiary są wymiarami projektowanymi i mogą być różne od wymiarów powykonawczych.
                            </Typography>
                            <br />
                            <Fab variant="extended" size="small"
                                className="ml-2"
                                target="_blank"
                                href="/files/B1-1.pdf">
                                <PictureAsPdfIcon sx={{ mr: 1 }} />
                                Metryczka
                            </Fab>
                            <Fab variant="extended" size="small"
                                className="m-1"
                                target="_blank"
                                href="/files/Standard wykonania domu - Baczynskiego 54.pdf">
                                <PictureAsPdfIcon sx={{ mr: 1 }} />
                                Standard wykonania
                            </Fab>
                        </Col>
                    </Row>
                    }
                </Container >
            </div>
            <MarketingGradientShadow />
        </>
    );
};
