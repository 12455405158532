import { IconButton } from '@mui/material';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const MarketingPageAnchor = React.forwardRef((
    props: {
        showBack?: boolean,
        white?: boolean,
    },
    ref: React.ForwardedRef<HTMLDivElement>) => {
    //const navigate = useNavigate();
    //function backClick() {
    //    navigate(MyRoutes.marketingPage("home"));
    //}
    return (
        <Container ref={ref} style={{
            height: '0px',
        }}  >
            <Row className="">
                <Col />
                <Col className="col-auto">
                    {props.showBack &&
                        <IconButton href="#home"
                            style={{
                            position: 'absolute',
                            right: '10px',
                            zIndex: 10,
                        }}>
                            {props.white
                                ? <img src="./img/icons/icon_up.svg" alt="back" />
                                : <img src="./img/icons/icon_up_dark.svg" alt="back" />
                            }
                        </IconButton>
                    }
                </Col>
            </Row>
        </Container>
    );
});
