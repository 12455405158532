import * as React from 'react';
import { useEffect, useState } from 'react';

export const AnimatedSlider = (props: {
    children?: React.ReactElement[],
    animationClassNames: string[],
    containerStyle?: React.CSSProperties,
    paused?: boolean,
}) => {
    const animTimer = 5;
    const childrenCount = props.children?.length ?? 0;
    const animationClassNamesCount = props.animationClassNames?.length ?? 0;

    const [animationClass, setAnimationClass] = useState(0);

    const getTime = () => {
        if (props.paused === true) return;

        setAnimationClass((prev) => prev + 1);
    }

    useEffect(() => {
        const interval = setInterval(() => getTime(), animTimer * 1000);

        return () => clearInterval(interval);
    }, [props.paused]);
    const currentChild = props.children && props.children[animationClass % childrenCount];
    const currentAnimation = props.animationClassNames[animationClass % animationClassNamesCount];

    return <>
        <div className={currentAnimation}
            style={props.containerStyle}
        >
            {currentChild}
        </div>
    </>;
}