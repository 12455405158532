import { MarketingPageTab } from "../marketing/MarketingMenu";

export class MyRoutes {

    static home = "/";
    static mobileHome = "/mobile/:client?/:version?";
    static terms = "/terms";
    static legal = "/legal";
    static marketing = "#:tab";
    static marketingPage(tab?: MarketingPageTab): string {
        return `#${tab ?? "home"}`;
    }
}