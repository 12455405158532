import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import * as React from 'react';
import { useState } from "react";
import { MyRoutes } from '../Pages/MyRoutes';
import { ScreenSizeListener } from "../ui/ScreenSizeListener";
import { ContactPhone } from "./ContactPhone";

export type MarketingPageTab =
    "home"
    | "park"
    | "inwestycja"
    | "domy"
    | "rzuty"
    | "oferta"
    | "co_nas_wyroznia"
    | "lokalizacja"
    | "kontakt";

export interface MenuItemData {
    text: string,
    link: MarketingPageTab,
}
export const menuContent: MenuItemData[] = [
    { text: 'Park Baczyńskiego', link: 'park' },
    { text: 'Inwestycja', link: 'inwestycja' },
    { text: 'Domy', link: 'domy' },
    { text: 'Rzuty', link: 'rzuty' },
    { text: 'Oferta', link: 'oferta' },
    { text: 'Co nas wyróżnia?', link: 'co_nas_wyroznia' },
    { text: 'Lokalizacja', link: 'lokalizacja' },
    { text: 'Kontakt', link: 'kontakt' },
];
export const MarketingAnchor = (props: {
    id: MarketingPageTab
}) => {
    return <div id={props.id} />
}

export const MarketingMenu = () => {
    //const navigate = useNavigate();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    //function menuClick(tab: MarketingPageTab) {
    //    closeMenuClick();
    //    navigate(MyRoutes.marketingPage(tab));
    //}
    function openMenuClick(event: React.MouseEvent<HTMLElement>) {
        setMenuAnchor(event.currentTarget);
    }
    function closeMenuClick() {
        setMenuAnchor(null);
    }
    return (
        <>
            <AppBar position="static" color="transparent" elevation={0}>
                <ScreenSizeListener threshold="md"
                    whenAboveThreshold={
                        <Toolbar>
                            {menuContent.map((m, i) =>
                                <Button key={m.text} href={MyRoutes.marketingPage(m.link)}
                                    className="text-black text-black-bold2">
                                    {m.text}
                                </Button>
                            )}
                            <div className="ml-auto">
                                <ContactPhone />
                            </div>
                        </Toolbar>
                    }
                    whenThresholdOrBelow={<>
                        <Toolbar >
                            <IconButton style={{
                                position: 'absolute',
                            }}
                                onClick={openMenuClick}
                            >
                                <img src="./img/marketing/menu-dark.svg" alt="menu" />
                            </IconButton>
                            <div className="ml-auto">
                                <ContactPhone />
                            </div>
                        </Toolbar>
                        <Menu anchorEl={menuAnchor}
                            open={Boolean(menuAnchor)}
                            onClose={closeMenuClick}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {menuContent.map((m, i) =>
                                <MenuItem key={m.text}>
                                    <Button href={MyRoutes.marketingPage(m.link)}
                                        onClick={closeMenuClick}
                                        fullWidth
                                        className="text-black text-black-bold2">
                                        {m.text}
                                    </Button>
                                </MenuItem>
                            )}
                        </Menu>
                    </>}
                />
            </AppBar>
        </>
    );
};
