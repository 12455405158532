import { Button, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import '@typeform/embed/build/css/popup.css';
import * as React from 'react';
import { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { MyRoutes } from '../Pages/MyRoutes';
import { EmiCard } from "../ui/EmiCard";
import { DialogDziennikBudowy } from "./DialogDziennikBudowy";
import { DialogOlawa } from "./DialogOlawa";
import { MarketingGradientShadow, MarketingGradientShadowInverse } from "./MarketingGradientShadow";
import { MarketingAnchor } from './MarketingMenu';
import { StyledTitleRow } from './StyledTitle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            backgroundImage: 'url(./img/marketing/subscribe-bg.svg)',
        },
        circles: {
            backgroundImage: 'url(./img/marketing/subscribe-circles.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            padding: '8px 26px',
            marginTop: '26px',
            width: '100%',
            height: '100%',
            position: 'absolute',
            opacity: '0.4',
        },
        btnRegister: {
            textTransform: 'none',
            borderRadius: '30px',
            margin: 'auto',
            alignSelf: 'center',
            width: '280px',
        },
        button: {
            margin: '40px auto 20px auto',
            width: '85%',
            display: 'block',
            padding: '0px',
            borderRadius: 20,
            overflow: 'hidden',
            clip: 'auto',
            height: '40px',
            position: 'relative',
        },
        buttonBg: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'black',
            opacity: '0.2',
            top: '0px',
            left: '0px',
        },
        recommended: {
            border: '#ffffff 1px solid',
            borderRadius: 20,
            width: '60%',
            left: '0px',
            right: '0px',
            padding: '8px 16px',
            margin: '0px auto 0px auto',
            position: 'fixed',
            top: '10px',
        },
        shadow: {
            position: 'absolute',
            top: '70%',
            left: '15%',
            right: '15%',
            bottom: '-5%',
            filter: 'blur(10px)',
            opacity: '0.4',
            background: 'url(./img/bee-bg.svg)',
            backgroundClip: 'content-box',
            zIndex: -1,
        },
    }),
);
export const Marketing07_co_nas_wyroznia = () => {
    const [dialogDziennikBudowy, setDialogDziennikBudowy] = useState(false);
    const [dialogOlawa, setDialogOlawa] = useState(false);

    const classes = useStyles();

    return (
        <>
            <DialogDziennikBudowy open={dialogDziennikBudowy}
                onClose={() => setDialogDziennikBudowy(false)}
            />
            <DialogOlawa open={dialogOlawa}
                onClose={() => setDialogOlawa(false)}
            />
            <MarketingGradientShadowInverse />
            <MarketingAnchor id="co_nas_wyroznia" />
            <div className="bg-gradient-bee-diagonal">

                <Container >
                    <StyledTitleRow text="Co nas wyróżnia?" white />
                    <Row className="mt-5">
                        <Col xs={12} lg={true} className="">
                            <EmiCard className={"bg-gradient-05 my-3 "} elevation={12}>
                                <div className="z1000">
                                    <Typography variant="h4"
                                        className="text-white-shadow"
                                        align="center" style={{
                                            marginTop: '80px',
                                            marginBottom: '40px',
                                            fontWeight: 'bold',
                                        }}>
                                        Przestrzeń
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        style={{
                                            fontWeight: 'bold',
                                        }}>
                                        •	Teren rekreacyjny wewnątrz osiedla
                                        <br />
                                        •	Ogrody przy każdym domu
                                        <br />
                                        •	Wygodne i obszerne domy
                                        <br />
                                        •	Duże przeszklenia doświetlające wnętrze
                                        <br />
                                    </Typography>
                                    <div className={classes.button}>
                                        <div className={classes.buttonBg}>
                                        </div>
                                        <Button href={MyRoutes.marketingPage("domy")}
                                            className="text-white w-100 m-0 h-100" style={{
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}>
                                            Domy w zieleni
                                        </Button>
                                    </div>
                                </div>
                            </EmiCard>
                        </Col>
                        <Col xs={12} lg={true} className="">
                            <EmiCard className={"bg-gradient-07 my-3"} elevation={12}>
                                <div className="z1000">
                                    <Typography variant="h4"
                                        className="text-white"
                                        align="center" style={{
                                            marginTop: '80px',
                                            marginBottom: '40px',
                                            fontWeight: 'bold',
                                            textShadow: '0px 2px 8px #000000'
                                        }}>
                                        Lokalizacja
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        style={{
                                            fontWeight: 'bold',
                                        }}>
                                        •	Bliskość rzeki i tras spacerowo-rowerowych
                                        <br />
                                        •	Spokojna, dzielnica willowa Oławy
                                        <br />
                                        •	Blisko wylotu na drogę Wrocław-Opole
                                        <br />
                                        •	Blisko infrastruktura - sklepy, szkoły, przychodnie, szpital
                                        <br />
                                    </Typography>
                                    <div className={classes.button}>
                                        <div className={classes.buttonBg}>
                                        </div>
                                        <Button onClick={() => setDialogOlawa(true)}
                                            className="text-white w-100 m-0 h-100" style={{
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}>
                                            Oława
                                        </Button>
                                    </div>
                                </div>

                            </EmiCard>
                        </Col>
                        <Col xs={12} lg={true} className="">
                            <EmiCard className={"bg-gradient-08 my-3"} elevation={12}>
                                <div className="z1000">
                                    <Typography variant="h4"
                                        className="text-white"
                                        align="center" style={{
                                            marginTop: '80px',
                                            marginBottom: '40px',
                                            fontWeight: 'bold',
                                            textShadow: '0px 2px 8px #000000'
                                        }}>
                                        Projekt
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        style={{
                                            fontWeight: 'bold',
                                        }}>
                                        •	Jasne wnętrza
                                        <br />
                                        •	Ustawne pokoje
                                        <br />
                                        •	Duża otwarta przestrzeń na parterze
                                        <br />
                                        •	Zadaszony taras
                                        <br />
                                        •	Przestrzeń do przechowywania (spiżarnia, pomieszczenie gospodarcze, strych do zagospodarowania)
                                        <br />
                                        •	Możliwość podłączenia kominka w salonie
                                        <br />
                                        •	Nowoczesny design
                                        <br />
                                    </Typography>
                                    <div className={classes.button}>
                                        <div className={classes.buttonBg}>
                                        </div>
                                        <Button href={MyRoutes.marketingPage("rzuty")}
                                            className="text-white w-100 m-0 h-100" style={{
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}>
                                            Rzuty
                                        </Button>
                                    </div>
                                </div>

                            </EmiCard>
                        </Col>
                        <Col xs={12} lg={true} className="">
                            <EmiCard className={"bg-gradient-10 mt-3 mb-5"} elevation={12}>
                                <div className="z1000">
                                    <Typography variant="h4"
                                        className="text-white"
                                        align="center" style={{
                                            marginTop: '80px',
                                            marginBottom: '40px',
                                            fontWeight: 'bold',
                                            textShadow: '0px 2px 8px #000000'
                                        }}>
                                        Wykonanie
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        style={{
                                            fontWeight: 'bold',
                                        }}>
                                        •	Ściany zbudowane z bloczków ceramicznych
                                        <br />
                                        •	Ocieplenie zewnętrzne ścian 18 cm styropianu
                                        <br />
                                        •	Dwuwarstwowe ocieplenie dachu wełną 20cm i 5 cm
                                        <br />
                                        •	Potrójne szyby zespolone
                                        <br />
                                        •	Podwójne ściany z dylatacją między segmentami
                                        <br />
                                        •	Instalacja gazowa
                                        <br />
                                        •	Ogrzewanie podłogowe
                                        <br />
                                        •	Dodatkowo podłączenie pod grzejnik drabinkowy w łazience
                                        <br />
                                        •	Automatyczna brama garażowa
                                        <br />
                                        •	Posadzka wzmocniona włóknami syntetycznymi
                                        <br />
                                        •	Parapety wewnętrzne w kolorze kremowym z konglomeratu,
                                        <br />
                                        •	Parapety zewnętrzne granitowe
                                        <br />
                                    </Typography>
                                    <div className={classes.button}>
                                        <div className={classes.buttonBg}>
                                        </div>
                                        <Button onClick={() => setDialogDziennikBudowy(true)}
                                            className="text-white w-100 m-0 h-100" style={{
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}>
                                            Dziennik budowy
                                        </Button>
                                    </div>
                                </div>
                            </EmiCard>
                        </Col>
                    </Row>
                    <Row />
                </Container>
            </div>
            <MarketingGradientShadow />
        </>
    );
};
