import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Paper, Typography } from '@mui/material';
import * as React from 'react';

export function ContactEmail(props: {
}) {

    return <a href="mailto:agata@park-baczynskiego.pl?subject=Zapytanie" className="a-no-underline">
        <Paper elevation={2} className="p-2 " square>
            <Typography variant="subtitle2"
                className="text-black"
            >
                                            <ContactSupportIcon sx={{ mr: 1,color:'gold' }} />
                agata@park-baczynskiego.pl
            </Typography>
            <div className="bg-gradient-gold effectShadowLight-silver d-none"
                style={{
                    zIndex: 4,
                    position: 'relative',
                    height: '2px',
                    width: '100%',
                }} />
        </Paper>
    </a>
}
export function ContactPhone(props: {
}) {

    return <a href="callto:+48509958294" className="a-no-underline">
        <Paper elevation={2} className="p-2 " square>
            <Typography variant="subtitle2"
                className="text-black"
            >
                                            <ContactSupportIcon sx={{ mr: 1,color:'gold' }} />
                +48 509 958 294
            </Typography>
            <div className="bg-gradient-gold effectShadowLight-silver d-none"
                style={{
                    zIndex: 4,
                    position: 'relative',
                    height: '2px',
                    width: '100%',
                }} />
        </Paper>
    </a>
}