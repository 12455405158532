import { Button, ButtonGroup, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        group: {
            borderRadius: '20px !important',
            backgroundColor: 'white',
        },
        btn: {
            borderRadius: '20px !important',
            border: 'none !important',
            margin: '4px 10px',
            padding: '4px 30px',
            textTransform: 'none',
            outline: 'none !important',
            '&:hover': {
            },
        },
        btn_first: {
            marginLeft: '4px',
        },
        btn_last: {
            marginRight: '4px',
        },
        btn_selected: {
        },
    })
);

export const TabsSwitch = (props: {
    selectedTab: number,
    onTabChanged: (selectedTab: number) => void,
    tabs: string[],
    groupClassName?: string,
}) => {
    const classes = useStyles();

    return (
        <ButtonGroup variant="contained" className={classes.group + " " + props.groupClassName}>
            {props.tabs.map((m, i) =>
                <Button key={m}
                    variant={i === props.selectedTab ? "contained" : "outlined"}
                    disableFocusRipple
                    className={classes.btn + " text-black-shadow "
                        + (i === 0 ? " " + classes.btn_first : "")
                        + ((i === props.tabs.length - 1) ? " " + classes.btn_last : "")
                    }
                    onClick={() => props.onTabChanged(i)}>
                    {m}
                </Button>)}
        </ButtonGroup>
    );
};
