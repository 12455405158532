import * as React from 'react';
import { Animations } from './Animations';

export function CspDivider(props: {
}) {

    return <div className={"bg-gradient-gold effectShadowLight-silver "
        + Animations.slide_in_left() + " "
        + Animations.delay(16)}
        style={{
            zIndex: 4,
            position: 'relative',
            height: '2px',
            width: '100%',
        }} />
}