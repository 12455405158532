import * as React from 'react';
import { CspDialog } from '../ui/CspDialog';
import { CspImageList } from '../ui/CspImageList';

export function DialogDziennikBudowy(props: {
    onClose: () => void,
    open: boolean,
}) {
    const imgPrefix = './img/dziennik-budowy/';
    const items = [
        { img: '2022-12-25 stan surowy zamkniety.jpeg', title: 'widok', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 stan surowy zamkniety (2).jpeg', title: 'widok', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 hall stan surowy zamkniety.jpeg', title: 'hall', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 pokoj 1 stan surowy zamkniety.jpeg', title: 'pokój 1', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 pokoj 2 stan surowy zamkniety.jpeg', title: 'pokój 2', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 pokoj 3 stan surowy zamkniety.jpeg', title: 'łazienka', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 pokoj 4 stan surowy zamkniety.jpeg', title: 'pokój 4', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 schody 2 stan surowy zamkniety.jpeg', title: 'schody 2', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 schody.jpeg', title: 'schody', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 schody1 stan surowy zamkniety.jpeg', title: 'schody', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 Wdok bok.jpeg', title: 'widok z boku', subtitle: 'stan surowy zamknięty', },
        { img: '2022-12-25 widok pole1.jpeg', title: 'widok z łąki', subtitle: '', },
        { img: '2022-12-25 widok pole2.jpeg', title: 'widok z łąki', subtitle: '', },
        { img: '2022-12-25 widok z balkonu.jpeg', title: 'widok z balkonu', subtitle: '', },
        { img: '2022-12-25 widok z pietra.jpeg', title: 'widok z piętra', subtitle: '', },
        { img: '2022-12-25 Widok zewnetrzny.jpeg', title: 'widok zewnętrzny', subtitle: '', },
        { img: '2021-09-03 ocieplenie - styropian9.jpg', title: 'front - brama', subtitle: 'izolacja termiczna', },
        { img: '2021-09-03 ocieplenie - styropian7.jpg', title: 'tył', subtitle: 'izolacja termiczna', },
        { img: '2021-09-03 ocieplenie - styropian5.jpg', title: 'lew front', subtitle: 'izolacja termiczna', },
        { img: '2021-09-03 ocieplenie - styropian3.jpg', title: 'prawy front', subtitle: 'izolacja termiczna', },
        { img: '2021-09-03 ocieplenie - styropian.jpg', title: 'od frontu', subtitle:  'izolacja termiczna', },
        { img: '2020-10-27 brama garazowa.JPG', title: 'brama garażowa', subtitle: 'prace montażowe', },
        { img: '2022-12-25 schody konstrukcja.jpeg', title: 'schody konstrukcja', subtitle: 'stan surowy zamknięty', },
        { img: '2020-05-13 sciany2.JPG', title: 'ściany', subtitle: 'roboty murarskie', },
        { img: '2020-05-08 sciany2.JPG', title: 'ściany', subtitle: 'roboty murarskie', },
        { img: '2020-05-08 sciany.JPG', title: 'ściany', subtitle: 'roboty murarskie', },
        { img: '2019-10-18 fundamenty.JPG', title: 'fundamenty', subtitle: 'roboty żelbetowe', },
        { img: '2019-10-18 fundamenty 2.JPG', title: 'fundamenty', subtitle: 'roboty żelbetowe', },
        { img: '2019-01-25 prace ziemne.JPG', title: '', subtitle: 'roboty ziemne', },
        { img: '2019-01-25 prace ziemne 2.JPG', title: '', subtitle: 'roboty ziemne', },
        { img: 'dzialka z lotu ptaka.png', title: 'lokalizacja', subtitle: 'tereny zielone', },
        { img: 'dzialka.png', title: 'lokalizacja', subtitle: 'tereny zielone', },
    ];

    return (
        <CspDialog onClose={props.onClose}
            open={props.open}
            title="Dziennik Budowy"
        >
            <CspImageList items={items} imgPrefix={imgPrefix} />
        </CspDialog>
    );
}